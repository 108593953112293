#mainWrapper .mainContents .conWrapper.fullWrapper .row{
  width: 100%;
  --bs-gutter-x: 0rem;
}

#mainWrapper .mainContents .conWrapper.fullWrapper .contents2 {
  background-color: var(--primary-color);
}
#mainWrapper .mainContents .conWrapper.fullWrapper .contents2 .redeemCons .con2 {
  margin: 0;
}
#mainWrapper .mainContents .conWrapper.fullWrapper .contents2 .redeemCons .title2 {
  margin: 0;
  width: 100%;
  color: #fff !important;
  text-align: center;
  padding: 8px 0;
  text-transform: uppercase;
}