@keyframes blink {
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
}

.blinking-text {
  animation: blink 2s linear infinite;
}

@media (max-width: 577px) {
  #mainWrapper .mainContents .conWrapper .contents.contents2 .detailCons .details .personal2 .rewards .cons {
    margin-bottom: 0px;
  }
}

.renewal-remarks {
  font-size: 14px;
  margin: 10px !important;
  color: #888888;
}
