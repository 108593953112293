.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --bs-link-color: var(--secondary-color);
  --bs-link-hover-color: var(--primary-color);
}

.wrapper {
  position: relative;
  width: fit-content;
}

.picker-box {
  position: absolute;
  width: fit-content;
  z-index: 1;
  left: 0;
}

.picker {
  cursor: pointer;
  background: transparent;
  border: 0;
  height: 40px;
  display: inline-block;
  width: calc(var(--litepickerMonthWidth) - 2rem);
  padding: 0 1rem;
}

.picker:hover,
.picker:focus {
  outline: none;
  box-shadow: none;
}

.form-group {
  display: inline-block;
  border: 1px solid #ccc;
}

.popup {
  position: fixed;
  inset: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, .7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup .wrapper {
  background-color: white;
  padding: 30px;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  overflow: auto;
}

.popup .button-container {
  display: flex;
  gap: .5rem;
  justify-content: center;
}
.small-btn {
  width: auto !important;
}

.popup button,
.btn {
  --bs-btn-hover-bg: var(--primary-color);
  --bs-btn-hover-color: white;
  --bs-btn-hover-border-color: var(--primary-color);
  --bs-btn-disabled-bg: var(--secondary-color);
  --bs-btn-disabled-color: white;
  --bs-btn-disabled-border-color: var(--secondary-color);
  --bs-nav-link-hover-color: white;
  border: 1px solid var(--secondary-color);
  background-color: var(--secondary-color);
  color: white;
  padding: .75em 1em;
  width: 100%;
  border-radius: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.popup button {
  max-width: 60%;
}

.popup button.disabled,
.btn.btn-cancel,
.btn.btn-outline, 
.btn.btn-outline-no-hover,
.btn.btn-outline-no-hover:active {
  background-color: white;
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.btn.btn-outline-no-hover:hover {
  cursor: initial;
}

.btn.btn-cancel:hover,
.btn.btn-outline:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}

.asiamilesBtn .nav-link {
  display: block;
  color: white;
  width: 100%;
  padding: .75em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.asiamilesBtn .nav-link p {
  margin: 0;
}

.asiamilesBtn .nav-link .icons {
  margin-right: .3em;
}

.btn-back {
  font-size: 0.8rem;
  display: flex;
  text-transform: uppercase;
  align-items: center;
}

.btn-back svg {
  margin-right: 6px;
  font-size: 23px;
}

[class*="asiamile-logo"],
[class*="cb-logo"] {
  --size: .8em;
  display: inline-block;
  text-indent: calc(var(--size) * 2);
  width: var(--size);
  height: var(--size);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

[class*="asiamile-logo"] {
  margin-right: .1em;
}

.asiamile-logo {
  background-image: url(../public/assets/img/icon-asiamile-black.svg);
}

.asiamile-logo-white {
  background-image: url(../public/assets/img/icon-asiamile-white.svg);
}

.asiamile-logo-red {
  background-image: url(../public/assets/img/icon-asiamile-red.svg);
}

.cb-logo {
  background-image: url(../public/assets/img/icon-b.svg);
}

.cb-logo-white {
  background-image: url(../public/assets/img/icon-b-white.svg);
}

.cb-logo-red {
  background-image: url(../public/assets/img/icon-b-red.svg);
}

.btn [class*="cb-logo"] {
  margin-bottom: 3px;
}

input::placeholder {
  font-size: 12px;
}

.inherit-color * {
  color: inherit;
}

@media (max-width: 577px) {
  .popup .button-container {
    flex-direction: column;
    gap: 1em;
  }

  .popup button {
    max-width: 100%;
  }
}