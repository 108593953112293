.scan-region-highlight-svg{
  stroke: #a72c32 !important;
} 

.qr-code-scanner-container {
  background-color: #888;
  margin-bottom: 90px;
  min-height: calc(100vh - 150px);
  padding-bottom: 30px;
  position: relative;
  text-align: center;

}
.qr-code-scanner-container .wrapper {
  align-items: center;
  height: 500px;
  border-radius: var(--bs-btn-border-radius,.375rem);
  display: flex;
  flex-direction: column;
  inset: 0;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.qr-code-scanner-container .wrapper p{
  color: #fff;
}
.qr-code-scanner-container .btn {
  background: none;
  border: 1px solid #fff;
  color: #fff;
  margin-bottom: 1em;
  padding: 8px 4px;
  width: 75%;
}

.opacity-visible{
  opacity: 1 !important
}

.scanner-hint {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  z-index: 10;
  text-align: center;
  padding: 1em;
}
