#page-whats-cb {
  background-color: white;
  padding-bottom: 2em;
}

#page-whats-cb .banner {
  height: 300px;
  background-color: black;
}

#page-whats-cb .banner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#page-whats-cb .submenu {
  padding: 2em 0;
  background-color: white;
  left: 0;
  right: 0;
  z-index: 990;
}

#page-whats-cb .submenu ul {
  list-style: none;
  display: flex;
  gap: 1em;
  justify-content: space-between;
  overflow: auto;
  padding: 0;
  margin: 0;
}

#page-whats-cb .submenu ul::-webkit-scrollbar {
  display: none;
}

#page-whats-cb .submenu ul li {
  width: 100%;
  padding: 8px;
  text-align: center;
  border: 1px solid transparent;
  color: #888;
  cursor: pointer;
  transition: all .3s ease;
  white-space: nowrap;
}

#page-whats-cb .submenu ul li.active,
#page-whats-cb .submenu ul li:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

#page-whats-cb .content-container .content {
  display: none;
}

#page-whats-cb .content-container .content.active {
  display: block;
}

#page-whats-cb .content-container .content .spacer {
  height: 50px;
}

#page-whats-cb .content-container .content .cbCards img {
  width: 90%;
}

#page-whats-cb .content-container .content .cbTable {
  border: none;
}
#page-whats-cb .content-container .content .cbTable td {
  border: none;
  padding: 20px 15px 40px 15px;
}
#page-whats-cb .content-container .content .cbTable td p {
  margin: 0;
}
#page-whats-cb .content-container .content .cbTable td ul {
  padding: 0;
  margin: 0 0 0 15px;
}
#page-whats-cb .content-container .content .cbTable .bg1 {
  background-color: #F4F4F4;
}
#page-whats-cb .content-container .content .cbTable .tbTitle {
  color: #888888;
  font-weight: bold;
  text-transform: uppercase;
}

@media (max-width: 577px) {
  #page-whats-cb .banner {
    height: 150px;
  }

  #page-whats-cb .banner img {
    object-fit: cover;
    object-position: 0% center;
  }

  #page-whats-cb .content-container .content .spacer {
    height: 50px;
  }

  #page-whats-cb .content-container .content .spacer2 {
    height: 150px;
  }

  #page-whats-cb .content-container .content .cbCards img {
    width: 100%;
  }

  #page-whats-cb .content-container .content .cbTable {
    border: none;
  }

  #page-whats-cb .content-container .content .cbTable th img {
    width: 128px;
    max-width: 128px;
  }

  #page-whats-cb .content-container .content .cbTable th:not(:nth-child(1)) {
    min-width: 90vw;
  }

  #page-whats-cb .content-container .content .cbCards {
    position: relative;
  }

  #page-whats-cb .content-container .content .cbCards:nth-of-type(2),
  #page-whats-cb .content-container .content .cbCards:nth-of-type(3) {
    max-height: 2em;
    overflow: hidden;
    transition: all .3s linear;
  }

  #page-whats-cb .content-container .content .cbCards.active {
    max-height: 600px !important;
  }

  #page-whats-cb .content-container .content .cbCards:nth-of-type(2):before {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    position: absolute;
    top: 3px;
    right: 0;
    height: 2em;
    width: 2em;
    transform: rotate(90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;
  }

  #page-whats-cb .content-container .content .cbCards.active:before {
    transform: rotate(270deg);
  }

  #page-whats-cb .content-container .content .cbCards:nth-of-type(3) {
    max-height: 0;
  }
}